import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.grey300};
  border-radius: 5px;
  padding: 1rem;
`;

const Title = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.25rem;
  margin: 1rem 0;

  &::before {
    content: ' ';
    border: 2px solid ${({ theme }) => theme.primary500};
    display: block;
    margin-bottom: 1rem;
    width: 32px;
  }
`;

interface Props {
  title: string;
  children?: React.ReactNode;
}

function Internship({ title, children }: Props): JSX.Element {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
}

export default Internship;
