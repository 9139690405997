import React from 'react';
import styled from 'styled-components';
import { Typography } from 'amphitheatre';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import Internship from 'src/Components/Internship';
import SEO from 'src/Components/SEO';

import * as Base from 'src/page-styles/base.styled';

const { Header } = Typography;

const InternshipWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

function Internships(): JSX.Element {
  return (
    <>
      <SEO
        title="Internships | Saber Astronautics"
        description="For an internship like no other, join the team at Saber to become an integral part of the team, gaining practical knowledge and hands on experience."
        url="https://www.saberastro.com/internships"
      />
      <Layout>
        <Container.Dark>
          <Base.MenuGap />
          <Base.Text>
            <StaticImage src="../images/interns.jpg" alt="internship" />
            <Base.Gap />
            <Base.Heading>INTERNSHIPS</Base.Heading>
            <Base.Paragraph>
              At Saber Astronautics, students play an integral role in our
              research and development programs whilst gaining hands-on
              experience. Work is challenging but fair, where you get to learn
              about the next generation in space operations, are paid for your
              time, and provided valuable support to kick-start your career.
            </Base.Paragraph>
            <Base.Paragraph>
              Saber has trained nearly 200 space engineers, many of whom
              graduate into roles with NASA, ESA, CSIRO, US Space Force, and
              many well-known commercial space ventures.
            </Base.Paragraph>
            <Base.Paragraph>
              Summer Internships are available. The application window for Saber
              USA is from March to May. And the window for internship
              applications at Saber Australia is from July to 1st of September.
            </Base.Paragraph>
            <Base.Paragraph>
              What are we looking for?
              <ul>
                <li>
                  A genuine passion for space (come prepared with your favourite
                  sci fi series)
                </li>
                <li>Curiosity and innovation</li>
                <li>Thoroughness and attention to detail</li>
              </ul>
            </Base.Paragraph>
            <Base.Gap />
            <Header>Applying</Header>
            <Base.Paragraph>
              Prepare your application with your CV, cover letter, code samples
              and a sample of your work if applicable and proof of
              citizenship/permanent residency. Let us know what you're
              interested in working on!
            </Base.Paragraph>
            {/* <Base.Paragraph>
              Apply today and see where your passion for space can take you!
            </Base.Paragraph> */}
            <Header level={3}>
              Send your completed application to our{' '}
              <a href="mailto:careers.aus@saberastro.com">Australia</a> or{' '}
              <a href="mailto:careers.usa@saberastro.com">USA</a> offices.
            </Header>
            <Base.Paragraph>
              Please note,{' '}
              <b>
                all internships require the applicant to hold a current
                citizenship
              </b>{' '}
              for the nation in which their Saber Astronautics employment will
              be based. This is a non negotiable requirement due to the secure
              nature of the work.
            </Base.Paragraph>
            <Base.Gap />
            <Header>Internships</Header>
            <InternshipWrapper>
              <Internship title="Space Engineer Internship">
                <Base.Paragraph>
                  Knowledge in Space Environment phenomenon such as solar
                  radiation and magnetic field models are essential. Successful
                  candidates will apply space physics to real world problems so
                  an understanding of algorithms is important.
                </Base.Paragraph>
                <Base.Paragraph>
                  Python programming skills are essential.
                </Base.Paragraph>
              </Internship>
              <Internship title="Software Engineer Internship">
                <Base.Paragraph>
                  Knowledge in Python, or C# and Unity is essential for this
                  position. Experience in space operations is not required, but
                  greatly reduces training times required.
                </Base.Paragraph>
              </Internship>
              <Internship title="Avionics Engineer Internship">
                <Base.Paragraph>
                  Knowledge in Embedded C, C++ and Python is essential. Signal
                  theory, processing and communications skills (SDR, Antennas
                  etc) essential. PCB Design (CAD) as well as population highly
                  recommended.
                </Base.Paragraph>
              </Internship>
              <Internship title="DIIP Internship">
                <Base.Paragraph>
                  12 Week Full-Time Summer Internship
                </Base.Paragraph>
                <Base.Paragraph>
                  Paid internship hosted through the Australian Defence Industry
                  Internship Program. Apply through the{' '}
                  <a href="https://diip.com.au">DIIP</a> application portal,
                  select Saber as the preferred company through our SME codes:
                </Base.Paragraph>
                <Base.Paragraph>
                  Sydney Engineering: 2023.08E <br />
                  Sydney Technology: 2023.08T <br />
                  Adelaide Engineering: 2023.09E <br />
                  Adelaide Technology: 2023.09T
                </Base.Paragraph>
                <Base.Paragraph>
                  Australian citizenship is required.
                </Base.Paragraph>
              </Internship>
            </InternshipWrapper>
          </Base.Text>
        </Container.Dark>
      </Layout>
    </>
  );
}

export default Internships;
